$color-black: #000;
$color-white: #fff;
$color-grey: #383838;
$color-darkgrey: #1b1b1b;
$color-lightgrey: #ececec;
$color-red: #F70B57;
$color-lightgreen: #E0F8C5;
$color-green: #74bfa1;
$color-succes-greed: #32BEA6;
$color-gold: #FFC107;

$font-family-heading: 'Alfa Slab One', cursive;
$font-family-main: 'Roboto', sans-serif;;

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;

$trans: 0.2s ease;

$xs: 450px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin container($width: 1200px) {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: $width;
}