@import '../../scss/variables';

.Footer {
  background-color: $color-darkgrey;
  padding: 30px 0;

  .Container {
    @include container();

    > p {
      font-size: $font-md;
      text-align: center;
      color: $color-white;
    }
  }
}