@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap);
.Navigation_Navigation__1PnRV{display:flex;align-items:center;justify-content:flex-start}.Navigation_Navigation__1PnRV>a{margin-right:25px;font-size:16px;text-decoration:none;color:#fff}.Navigation_Navigation__1PnRV>a.active{color:#E0F8C5}.Navigation_Navigation__1PnRV>a:last-child{margin-right:0}

.Header_Header__36L4j{padding:5px 0;background-color:#1b1b1b}.Header_Header__36L4j .Header_Container__3a888{margin:0 auto;padding-left:15px;padding-right:15px;max-width:1200px;display:grid;grid-template-columns:40px 1fr auto;grid-column-gap:40px;-webkit-column-gap:40px;column-gap:40px;align-content:center}

.Footer_Footer__2nCAF{background-color:#1b1b1b;padding:30px 0}.Footer_Footer__2nCAF .Footer_Container__3WIq5{margin:0 auto;padding-left:15px;padding-right:15px;max-width:1200px}.Footer_Footer__2nCAF .Footer_Container__3WIq5>p{font-size:16px;text-align:center;color:#fff}

*{margin:0;padding:0;font-family:"Roboto",sans-serif;letter-spacing:0.5px;color:#000;box-sizing:border-box}*:focus{outline:none}body button{display:inline-block;padding:5px 15px;color:#fff;background-color:#1b1b1b;border:1px solid #1b1b1b;transition:color 0.2s ease,background-color 0.2s ease,border-color 0.2s ease;cursor:pointer}body button:hover{background-color:#000}body a{display:inline-block;color:#000;text-decoration:underline;transition:color 0.2s ease,background-color 0.2s ease,border-color 0.2s ease}body a:hover{color:#F70B57}body img{max-width:100%}body input,body select,body textarea{padding:5px 10px;font-size:16px;color:#000;background-color:#fff;border:1px solid #000}.loading{display:flex;justify-content:center;align-items:center;padding-top:20px;padding-bottom:20px;min-height:100vh}

