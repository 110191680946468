* {
  margin: 0;
  padding: 0;
  font-family: $font-family-main;
  letter-spacing: 0.5px;
  color: $color-black;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

body {
  button {
    display: inline-block;
    padding: 5px 15px;
    color: $color-white;
    background-color: $color-darkgrey;
    border: 1px solid $color-darkgrey;
    transition: color $trans, background-color $trans, border-color $trans;
    cursor: pointer;

    &:hover {
      background-color: $color-black;
    }
  }

  a {
    display: inline-block;
    color: $color-black;
    text-decoration: underline;
    transition: color $trans, background-color $trans, border-color $trans;

    &:hover {
      color: $color-red;
    }
  }

  img {
    max-width: 100%;
  }

  input,
  select,
  textarea {
    padding: 5px 10px;
    font-size: $font-md;
    color: $color-black;
    background-color: $color-white;
    border: 1px solid $color-black;
  }
}