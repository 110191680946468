@import '../../scss/variables';

.Header {
  padding: 5px 0;
  background-color: $color-darkgrey;

  .Container {
    @include container();
    display: grid;
    grid-template-columns: 40px 1fr auto;
    column-gap: 40px;
    align-content: center;
  }
}