@import '../../../scss/variables';

.Navigation {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > a {
    margin-right: 25px;
    font-size: $font-md;
    text-decoration: none;
    color: $color-white;

    &:global(.active) {
      color: $color-lightgreen;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}